import {
    defaultShippingCountry,
    ShippingCountry,
    validShippingCountries,
} from './ShippingCountry';

export const findShippingCountry = (
    inputCountryCode: string
): ShippingCountry => {
    const desireCountry = validShippingCountries.find(
        (country) => country.countryCode === inputCountryCode
    );

    return desireCountry ?? defaultShippingCountry;
};
