import { Button, Container } from 'react-bootstrap';
import { Item, useCart } from 'react-use-cart';

import StateHandler from '../domain/StateHandler';
import { formatPrice } from '../helpers/FormatPrice';
import { useConfigState } from '../state/config/ConfigContext';
import { LoadingType, useProductsState } from '../state/ProductsContext';

function ProductsPage() {
    const { configData } = useConfigState();
    const { productsData, productsLoadingStatus, productsError } =
        useProductsState();
    const { addItem } = useCart();

    const handleAddToCart = (priceId: string, unitAmount: number) => {
        const item: Item = {
            id: priceId,
            price: unitAmount,
        };
        addItem(item);
    };

    const loading = productsLoadingStatus === LoadingType.Loading;

    return (
        <StateHandler error={productsError} isPageLoading={loading}>
            <Container>
                <h1>Products</h1>
                {productsData.map((x) => (
                    <li key={x.productId}>
                        {`Active: ${x.active} Name: ${x.name} Des: ${
                            x.description
                        } Id: ${x.productId} Image: ${x.image} ${
                            x.price
                                ? `Price: ${formatPrice(
                                      x.price.unitAmount,
                                      configData.selectedCurrency
                                  )}`
                                : ''
                        }`}
                        {x.price && (
                            <Button
                                onClick={() =>
                                    handleAddToCart(
                                        x.productId,
                                        x.price!.unitAmount
                                    )
                                }
                            >
                                Add to bag
                            </Button>
                        )}
                    </li>
                ))}
            </Container>
        </StateHandler>
    );
}

export default ProductsPage;
