import React from 'react';
import CookieConsent from 'react-cookie-consent';

export const CookieConsentBanner = () => {
    return (
        <CookieConsent
            location='bottom'
            buttonText='GOT IT'
            enableDeclineButton
            // onDecline={() => alert('Declined')}
            declineButtonText='Reject'
        >
            This site uses cookies | Some of them are essential while others are
            used to serve you a customized shopping experience.{' '}
            <a href='/cookie-policy'>Learn more</a>
        </CookieConsent>
    );
};
