import './index.scss';
// import 'bootstrap/dist/css/bootstrap.css';
import './theme-lux.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { CartProvider } from 'react-use-cart';

import App from './App';
import { ConfigProvider } from './state/config/ConfigContext';
import { ProductsProvider } from './state/ProductsContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ConfigProvider>
            <ProductsProvider>
                <CartProvider>
                    <App />
                </CartProvider>
            </ProductsProvider>
        </ConfigProvider>
    </React.StrictMode>
);
