import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useCart } from 'react-use-cart';

import OrderSummarySingle from '../../components/orderSummary/OrderSummarySingle';
import StateHandler from '../../domain/StateHandler';
import { getCheckoutSuccess } from '../../http/checkout/getCheckoutSuccess';
import { Order } from '../../http/order/Order';

function CheckoutSuccessPage() {
    const { search } = useLocation();
    const [order, setOrder] = useState<Order>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error>();

    const session_id = new URLSearchParams(search).get('session_id');
    useMemo(async () => {
        if (session_id) {
            await getCheckoutSuccess(session_id)
                .then((response) => {
                    setOrder(response);
                    setIsLoading(false);
                })
                .catch((error) => setError(error));
        }
    }, [session_id]);

    const { emptyCart } = useCart();
    useEffect(() => emptyCart(), [emptyCart]);

    return (
        <StateHandler error={error} isPageLoading={isLoading}>
            <Container>
                {session_id ? (
                    <div>
                        <p>Checkout success with session_id: {session_id}</p>

                        {order && <OrderSummarySingle order={order} />}
                    </div>
                ) : (
                    <div>
                        <p>Checkout success but no session_id provided</p>
                    </div>
                )}
            </Container>
        </StateHandler>
    );
}

export default CheckoutSuccessPage;
