import { backendClient } from '../backendClient';
import { Order } from '../order/Order';

export const getCheckoutSuccess = async (checkoutSessionId: string) =>
    await backendClient
        .get<Order>('/checkout/success', {
            params: { checkoutSessionId: checkoutSessionId },
        })
        .then((response) => {
            return response.data as Order;
        });
