import React, { useMemo, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import OrderSummarySingle from '../components/orderSummary/OrderSummarySingle';
import StateHandler from '../domain/StateHandler';
import { getOrder } from '../http/order/getOrder';
import { Order } from '../http/order/Order';

export const TrackOrder = () => {
    const { search } = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>();
    const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const orderIdQueryParam = new URLSearchParams(search).get('order_id');
    const [orderIdInput, setOrderIdInput] = useState<string>();

    const [order, setOrder] = useState<Order>();

    useMemo(async () => {
        if (orderIdQueryParam) {
            setIsLoading(true);
            await getOrder(orderIdQueryParam)
                .then((response) => {
                    setOrder(response);
                    setIsLoading(false);
                })
                .catch((error) => setError(error));
        }
    }, [orderIdQueryParam]);

    const handleOrderIdSubmit = async () => {
        if (orderIdInput) {
            setIsOrderLoading(true);
            await getOrder(orderIdInput)
                .then((response) => {
                    setOrder(response);
                    setIsOrderLoading(false);
                })
                .catch((error) => setError(error));
        }
    };

    return (
        <StateHandler error={error} isPageLoading={isLoading}>
            <Container>
                <h1>Track your order</h1>
                <div>
                    {isOrderLoading ? (
                        <p>Loading</p>
                    ) : (
                        <>
                            {order ? (
                                <OrderSummarySingle order={order} />
                            ) : (
                                <>
                                    <p>Enter an order ID</p>
                                    <input
                                        key='orderIdInput'
                                        placeholder='Order ID'
                                        onChange={(x) =>
                                            setOrderIdInput(x.target.value)
                                        }
                                        value={orderIdInput}
                                    ></input>
                                    <Button
                                        onClick={() => handleOrderIdSubmit()}
                                        disabled={!orderIdInput}
                                    >
                                        Enter
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Container>
        </StateHandler>
    );
};
