import { Container, Nav, Navbar } from 'react-bootstrap';
import { useCart } from 'react-use-cart';

import { ShippingCountrySelector } from '../shippingCountry/ShippingCountrySelector';

function Header() {
    const { totalItems } = useCart();

    return (
        <Navbar bg='light' expand='md' sticky='top'>
            <Container>
                <Navbar.Brand href='/'>suchamood</Navbar.Brand>
                <Navbar.Toggle></Navbar.Toggle>
                <Navbar.Collapse className='justify-content-end'>
                    <Nav className='ml-auto'>
                        <Nav.Link href='/products'>Products</Nav.Link>
                        <Nav.Link href='/track-order'>Track</Nav.Link>
                        <Nav.Link href='/contact'>Contact</Nav.Link>
                        <Nav.Link href='/checkout'>Bag: {totalItems}</Nav.Link>
                        <ShippingCountrySelector />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
