import { createContext, useContext, useReducer } from 'react';

import { getProducts } from '../http/products/getProducts';
import { Product } from '../http/products/Product';

export enum LoadingType {
    Ready = 'ready',
    Loading = 'loading',
    Loaded = 'loaded',
    Error = 'error',
}

const setProductsLoadingStatus = 'setProductsLoadingStatus';
const setProductsData = 'setProductsData';
const setProductsError = 'setProductsError';

type Action =
    | { type: typeof setProductsLoadingStatus; payload: LoadingType }
    | { type: typeof setProductsData; payload: Product[] }
    | { type: typeof setProductsError; payload: Error };

type Dispatch = (action: Action) => void;

interface State {
    productsLoadingStatus: LoadingType;
    productsData: Product[];
    productsError: Error | undefined;
}

const Reducer = (state: State, action: Action) => {
    switch (action.type) {
        case setProductsLoadingStatus: {
            const { payload } = action;
            const newState: State = {
                ...state,
                productsLoadingStatus: payload,
            };
            return newState;
        }
        case setProductsData: {
            const { payload } = action;
            const newState: State = { ...state, productsData: payload };
            return newState;
        }
        case setProductsError: {
            const { payload } = action;
            const newState: State = { ...state, productsError: payload };
            return newState;
        }
        default: {
            return { ...state };
        }
    }
};

export const ProductStateContext = createContext<State | undefined>(undefined);
export const ProductsDispatchContext = createContext<Dispatch | undefined>(
    undefined
);

interface ProviderProps {
    children: React.ReactNode;
}

const ProductsProvider = ({ children }: ProviderProps) => {
    const [state, dispatch] = useReducer(Reducer, {
        productsData: [],
        productsLoadingStatus: LoadingType.Ready,
        productsError: undefined,
    });

    return (
        <ProductStateContext.Provider value={state}>
            <ProductsDispatchContext.Provider value={dispatch}>
                {children}
            </ProductsDispatchContext.Provider>
        </ProductStateContext.Provider>
    );
};

const fetchProducts = async (dispatch: Dispatch, currency: string) => {
    dispatch({
        type: setProductsLoadingStatus,
        payload: LoadingType.Loading,
    });

    await getProducts(currency)
        .then((response) => {
            dispatch({
                type: setProductsLoadingStatus,
                payload: LoadingType.Loaded,
            });
            dispatch({
                type: setProductsData,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: setProductsLoadingStatus,
                payload: LoadingType.Error,
            });
            dispatch({
                type: setProductsError,
                payload: error,
            });
        });
};

const useProductsState = () => {
    const context = useContext(ProductStateContext);
    if (!context)
        throw new Error('useProductsState must be within a ProductsProvider');

    return context;
};

const useProductsDispatch = () => {
    const context = useContext(ProductsDispatchContext);
    if (!context)
        throw new Error(
            'useProductsDispatch must be within a ProductsProvider'
        );

    return context;
};

export {
    ProductsProvider,
    useProductsState,
    fetchProducts,
    useProductsDispatch,
};
