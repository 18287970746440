import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Footer } from './components/footer/Footer';
import Header from './components/header/Header';
import CheckoutPage from './pages/checkout/CheckoutPage';
import CheckoutSuccessPage from './pages/checkout/CheckoutSuccessPage';
import { Contact } from './pages/Contact';
import HomePage from './pages/HomePage';
import { CookiePolicy } from './pages/policies/CookiePolicy';
import { PrivacyPolicy } from './pages/policies/PrivacyPolicy';
import { ShippingAndReturnsPolicy } from './pages/policies/ShippingAndReturnsPolicy';
import { TermsOfService } from './pages/policies/TermsOfService';
import ProductsPage from './pages/ProductsPage';
import { TrackOrder } from './pages/TrackOrder';
import {
    initialiseConfig,
    useConfigDispatch,
    useConfigState,
} from './state/config/ConfigContext';
import { fetchProducts, useProductsDispatch } from './state/ProductsContext';

function App() {
    const configDispatch = useConfigDispatch();
    const { configData } = useConfigState();
    useEffect(() => {
        initialiseConfig(configDispatch);
    }, [configDispatch]);

    const productsDispatch = useProductsDispatch();
    useEffect(() => {
        fetchProducts(productsDispatch, configData.selectedCurrency);
    }, [configData.selectedCurrency, productsDispatch]);

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/products' element={<ProductsPage />} />
                <Route path='/checkout' element={<CheckoutPage />} />
                <Route
                    path='/checkout/success'
                    element={<CheckoutSuccessPage />}
                />
                <Route path='/track-order' element={<TrackOrder />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/terms-of-service' element={<TermsOfService />} />
                <Route
                    path='/shipping-and-returns-policy'
                    element={<ShippingAndReturnsPolicy />}
                />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
