import { Item } from 'react-use-cart';

import { backendClient } from '../backendClient';
import { Product } from '../products/Product';
import { CheckoutParams } from './CheckoutParams';

export const redirectToCheckout = async (
    items: Item[],
    shippingCountry: string,
    products: Product[]
) => {
    await getCheckoutUrl(items, shippingCountry, products).then(
        (checkoutUrl) => checkoutUrl && (window.location.href = checkoutUrl)
    );
};

const getCheckoutUrl = async (
    items: Item[],
    shippingCountry: string,
    products: Product[]
) => {
    const postBody: CheckoutParams = {
        items: items.map((x) => {
            return {
                priceId: findProductPriceId(x.id, products),
                quantity: x.quantity ?? 0,
            };
        }),
    };

    return await backendClient
        .post(`/checkout`, postBody, {
            params: {
                shippingCountry: shippingCountry,
            },
        })
        .then((response) => response.data as string);
};

const findProductPriceId = (itemId: string, products: Product[]) => {
    const product = products.find((product) => product.productId === itemId);
    if (product && product.price?.priceId) {
        return product.price.priceId;
    } else {
        throw new Error(`Price ID not found for ${itemId}`);
    }
};
