import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';

export const ShippingAndReturnsPolicy = () => {
    const returnsPolicy = useRef(null);

    const scrollToSection = (elementRef: any) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <Container>
            <h1>SHIPPING & DELIVERY POLICY</h1>
            <p>
                This Shipping & Delivery Policy is part of our Terms of Service
                ("Terms") and should be therefore read alongside our main Terms
                here:{' '}
                <a href='https://suchamood.com/terms-of-service'>
                    https://suchamood.com/terms-of-service
                </a>
                . Please carefully review our Shipping & Delivery Policy when
                purchasing our products. This policy will apply to any order you
                place with us.
            </p>

            <h2>WHAT ARE MY SHIPPING & DELIVERY OPTIONS?</h2>
            <h3>Free UK Shipping</h3>
            <p>We offer free 3-5 Days UK shipping for UK Customers.</p>
            <p>
                All times and dates given for delivery of the products are given
                in good faith but are estimates only.
            </p>
            <p>
                For EU and UK consumers: This does not affect your statutory
                rights. Unless specifically noted, estimated delivery times
                reflect the earliest available delivery, and deliveries will be
                made within 30 days after the day we accept your order. For more
                information please refer to our Terms.
            </p>

            <h3>International Shipping</h3>
            <p>We offer international shipping to the following countries:</p>
            <li>France</li>
            <li>Germany</li>
            <li>Italy</li>
            <li>United States</li>
            <li>Canada</li>
            <p>
                International shipping cost is calculated at checkout and Free
                UK Shipping is not valid on international orders.
            </p>

            <h3>Taxes</h3>
            <li>
                <strong>UK:</strong> VAT is already included for all products.
            </li>
            <li>
                <strong>International:</strong> Due to the customs policy of
                different countries, we CANNOT determine the taxes for each
                country/region. You will be responsible for paying the customs
                charge.
            </li>

            <p>
                Please note, we may be subject to various rules and restrictions
                in relation to some international deliveries and you may be
                subject to additional taxes and duties over which we have no
                control. If such cases apply, you are responsible for complying
                with the laws applicable to the country where you live and will
                be responsible for any such additional costs or taxes
            </p>

            <h2>WHAT HAPPENS IF MY ORDER IS DELAYED?</h2>
            <p>
                If delivery is delayed for any reason we will let you know as
                soon as possible and will advise you of a revised estimated date
                for delivery.
            </p>
            <p>
                For EU and UK consumers: This does not affect your statutory
                rights. For more information please refer to our Terms.
            </p>

            <h2>QUESTIONS ABOUT RETURNS?</h2>
            <p>
                If you have questions about returns, please review our Returns
                Policy{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => scrollToSection(returnsPolicy)}
                >
                    below
                </span>
            </p>

            <h2>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
            <p>
                If you have any further questions or comments, you may contact
                us at:{' '}
                <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>
            </p>
            <h1 ref={returnsPolicy}>RETURNS POLICY</h1>
            <p>
                Thank you for your purchase. We hope you are happy with your
                purchase. However, if you are not completely satisfied with your
                purchase for any reason, you may return it to us for a full
                refund or an exchange. Please see below for more information on
                our return policy.
            </p>
            <h2>RETURNS</h2>
            <p>
                All returns must be postmarked within fourteen (14) days of
                receipt. All returned items must be in new and unused condition,
                with all original packaging and labels attached.
            </p>
            <h2>RETURN PROCESS</h2>
            <p>
                To return an item, please email customer service at
                <a href='mailto:hello@suchamood.com'> hello@suchamood.com</a> to
                obtain a Returns Form. After receiving a returns form, place the
                item securely in its original packaging and the returns form
                provided, then mail your return to the advised address on the
                returns form. Please note, you will be responsible for all
                return shipping charges. We strongly recommend that you use a
                trackable method to mail your return.
            </p>
            <h2>REFUNDS</h2>
            <p>
                After receiving your return and inspecting the condition of your
                item, we will process your return or exchange. Please allow at
                least five (5) days from the receipt of your item to process
                your return or exchange. We will notify you by email when your
                return has been processed.
            </p>
            <h2>EXCEPTIONS</h2>
            <p>
                For defective or damaged products, please contact us at the
                contact details below to arrange a refund or exchange.
            </p>
            <h2>QUESTIONS</h2>
            <p>
                If you have any questions concerning our return policy, please
                contact us at:{' '}
                <a href='mailto:hello@suchamood.com'> hello@suchamood.com</a>
            </p>
        </Container>
    );
};
