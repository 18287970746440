import { backendClient } from '../backendClient';
import { Product } from './Product';

export const getProducts = async (currency: string) =>
    await backendClient
        .get<Product[]>('/products', {
            params: { currency: currency },
        })
        .then((response) => {
            return response.data as Product[];
        });
