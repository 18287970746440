import { Container } from 'react-bootstrap';

function HomePage() {
    return (
        <Container>
            <p>Suchamood.com home page</p>
            <p>Mode: {process.env.NODE_ENV}</p>
        </Container>
    );
}

export default HomePage;
