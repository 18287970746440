import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useCart } from 'react-use-cart';

import { Config } from '../../state/config/Config';
import { changeConfig, useConfigDispatch, useConfigState } from '../../state/config/ConfigContext';
import { LoadingType, useProductsState } from '../../state/ProductsContext';
import { findNewPrice } from './findNewPrice';
import { findShippingCountry } from './findShippingCountry';
import { validShippingCountries } from './ShippingCountry';

export const ShippingCountrySelector = () => {
    const { productsData, productsLoadingStatus } = useProductsState();

    const { configData } = useConfigState();
    const dispatch = useConfigDispatch();

    const { items, updateItem } = useCart();

    useEffect(() => {
        if (productsLoadingStatus === LoadingType.Loaded) {
            items.forEach((item) =>
                updateItem(item.id, { price: findNewPrice(item, productsData) })
            );
        }
    }, [productsData, productsLoadingStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleShippingCountryChange = (newValue: string) => {
        const newConfig: Config = {
            selectedShippingCountry: newValue,
            selectedCurrency: findShippingCountry(newValue).currencyCode,
        };
        changeConfig(dispatch, newConfig);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle>
                {configData.selectedShippingCountry}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {validShippingCountries.map((shippingCountry) => (
                    <Dropdown.Item
                        key={shippingCountry.countryCode}
                        value={shippingCountry.countryCode}
                        onClick={() =>
                            handleShippingCountryChange(
                                shippingCountry.countryCode
                            )
                        }
                        disabled={
                            configData.selectedShippingCountry ===
                            shippingCountry.countryCode
                        }
                    >
                        {shippingCountry.countryName} (
                        {shippingCountry.countryCode}){' '}
                        {shippingCountry.currencySymbol}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
