import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useCart } from 'react-use-cart';

import StateHandler from '../../domain/StateHandler';
import { formatPrice } from '../../helpers/FormatPrice';
import { redirectToCheckout } from '../../http/checkout/redirectToCheckout';
import { useConfigState } from '../../state/config/ConfigContext';
import { LoadingType, useProductsState } from '../../state/ProductsContext';

function CheckoutPage() {
    const { configData } = useConfigState();
    const { productsData, productsLoadingStatus, productsError } =
        useProductsState();
    const [error, setError] = useState<Error>();

    const { items, emptyCart, cartTotal, removeItem } = useCart();

    const loading = productsLoadingStatus === LoadingType.Loading;

    const handleCheckout = () => {
        redirectToCheckout(
            items,
            configData.selectedShippingCountry,
            productsData
        ).catch((error) => setError(error));
    };

    const cartWithoutPrice = items.some((item) => item.price === undefined);
    const cartIsEmpty = items.length === 0;

    return (
        <StateHandler error={error || productsError}>
            <Container>
                <h1>Bag</h1>
                {items &&
                    items.map((x) => (
                        <li key={x.id}>
                            Id: {x.id} Quantity: {x.quantity}
                            {x.price &&
                                ` Price: ${formatPrice(
                                    x.price,
                                    configData.selectedCurrency
                                )}`}
                            <Button onClick={() => removeItem(x.id)}>
                                Remove item
                            </Button>
                        </li>
                    ))}
                {!cartIsEmpty && (
                    <Button onClick={() => emptyCart()}>Clear bag</Button>
                )}
                {cartWithoutPrice ? (
                    <div>Please remove the item without a price</div>
                ) : (
                    <p>
                        Bag total:{' '}
                        {formatPrice(cartTotal, configData.selectedCurrency)}
                    </p>
                )}
                <Button
                    onClick={() => handleCheckout()}
                    disabled={cartIsEmpty || loading || cartWithoutPrice}
                >
                    Checkout
                </Button>
            </Container>
        </StateHandler>
    );
}

export default CheckoutPage;
