import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';

export const PrivacyPolicy = () => {
    const tableOfContent = useRef(null);
    const oneWhatInformationDoWeCollect = useRef(null);
    const oneOnePersonalInformationYouDiscloseToUs = useRef(null);
    const twoHowDoWeProcessYourInformation = useRef(null);
    const threeWhatLegalBasesDoWeRelyOnToProcessYourInformation = useRef(null);
    const fourWhenAndWhomDoWeShareYourPersonalInformation = useRef(null);
    const fiveDoWeUseCookiesAndOtherTrackingTechnologies = useRef(null);
    const sixHowLongDoWeKeepYourInformation = useRef(null);
    const sevenHowDoWeKeepYourInformationSafe = useRef(null);
    const eightDoWeCollectInformationFromMinors = useRef(null);
    const nineWhatAreYourPrivacyRights = useRef(null);
    const tenControlsForDoNotTrackkFeatures = useRef(null);
    const elevenDoCaliforniaResidentsHaveSpecificPrivacyRights = useRef(null);
    const twelveDoWeMakeUpdatesToThisNotice = useRef(null);
    const thirteenHowCanYouContactUsAboutThisNotice = useRef(null);
    const fourteenHowCanYouReviewUpdateOrDeleteTheDataWeCollectFromYou =
        useRef(null);
    const withdrawYourConsent = useRef(null);

    const scrollToSection = (elementRef: any) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <Container>
            <h1>PRIVACY NOTICE</h1>
            <p>
                This privacy notice for Suchamood ('<strong>Company</strong>
                ', '<strong>we</strong>', '<strong>us</strong>', or '
                <strong>our</strong>',), describes how and why we might collect,
                store, use, and/or share ('<strong>process</strong>
                ') your information when you use our services ('
                <strong>Services</strong>'), such as when you:
            </p>
            <li>
                Visit our website at{' '}
                <a href='https://suchamood.com'>https://suchamood.com</a>, or
                any website of ours that links to this privacy notice
            </li>
            <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
            </li>
            <p>
                <strong>Questions or concerns?</strong> Reading this privacy
                notice will help you understand your privacy rights and choices.
                If you do not agree with our policies and practices, please do
                not use our Services. If you still have any questions or
                concerns, please contact us at{' '}
                <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2>SUMMARY OF KEY POINTS</h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our table of contents below to find the section you are
                    looking for. You can also click{' '}
                    <span
                        style={{
                            color: 'blue',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        onClick={() => scrollToSection(tableOfContent)}
                    >
                        here
                    </span>{' '}
                    to go directly to our table of contents.
                </strong>
            </p>
            <p>
                <strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with
                Suchamood and the Services, the choices you make, and the
                products and features you use. Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            oneOnePersonalInformationYouDiscloseToUs
                        )
                    }
                >
                    here
                </span>{' '}
                to learn more.
            </p>
            <p>
                <strong>
                    Do we process any sensitive personal information?{' '}
                </strong>
                We do not process sensitive personal information.
            </p>
            <p>
                <strong>
                    Do we receive any information from third parties?{' '}
                </strong>
                We do not receive any information from third parties.
            </p>
            <p>
                <strong>How do we process your information? </strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(twoHowDoWeProcessYourInformation)
                    }
                >
                    here
                </span>{' '}
                to learn more.
            </p>
            <p>
                <strong>
                    In what situations and with which types of parties do we
                    share personal information?{' '}
                </strong>
                We may share information in specific situations and with
                specific categories of third parties. Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            fourWhenAndWhomDoWeShareYourPersonalInformation
                        )
                    }
                >
                    here
                </span>{' '}
                to learn more.
            </p>
            <p>
                <strong>How do we keep your information safe? </strong> We have
                organisational and technical processes and procedures in place
                to protect your personal information. However, no electronic
                transmission over the internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorised
                third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information.
                Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(sevenHowDoWeKeepYourInformationSafe)
                    }
                >
                    here
                </span>{' '}
                to learn more.
            </p>
            <p>
                <strong>What are your rights? </strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(nineWhatAreYourPrivacyRights)
                    }
                >
                    here
                </span>{' '}
                here to learn more.
            </p>
            <p>
                <strong>How do you exercise your rights? </strong> The easiest
                way to exercise your rights is by filling out our data subject
                request form available in the account page:{' '}
                <a href='https://suchamood.com/account'>
                    https://suchamood.com/account
                </a>
                , or by contacting us. We will consider and act upon any request
                in accordance with applicable data protection laws.
            </p>
            <p>
                Want to learn more about what Suchamood does with any
                information we collect? Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => scrollToSection(tableOfContent)}
                >
                    here
                </span>{' '}
                to review the notice in full.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={tableOfContent}>TABLE OF CONTENTS</h2>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(oneWhatInformationDoWeCollect)
                    }
                >
                    1. WHAT INFORMATION DO WE COLLECT?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(twoHowDoWeProcessYourInformation)
                    }
                >
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            threeWhatLegalBasesDoWeRelyOnToProcessYourInformation
                        )
                    }
                >
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            fourWhenAndWhomDoWeShareYourPersonalInformation
                        )
                    }
                >
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            fiveDoWeUseCookiesAndOtherTrackingTechnologies
                        )
                    }
                >
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(sixHowLongDoWeKeepYourInformation)
                    }
                >
                    6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(sevenHowDoWeKeepYourInformationSafe)
                    }
                >
                    7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(eightDoWeCollectInformationFromMinors)
                    }
                >
                    8. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(nineWhatAreYourPrivacyRights)
                    }
                >
                    9. WHAT ARE YOUR PRIVACY RIGHTS?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(tenControlsForDoNotTrackkFeatures)
                    }
                >
                    10. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            elevenDoCaliforniaResidentsHaveSpecificPrivacyRights
                        )
                    }
                >
                    11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(twelveDoWeMakeUpdatesToThisNotice)
                    }
                >
                    12. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            thirteenHowCanYouContactUsAboutThisNotice
                        )
                    }
                >
                    13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
            </p>
            <p>
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            fourteenHowCanYouReviewUpdateOrDeleteTheDataWeCollectFromYou
                        )
                    }
                >
                    14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                </span>
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={oneWhatInformationDoWeCollect}>
                1. WHAT INFORMATION DO WE COLLECT?
            </h2>
            <h3 ref={oneOnePersonalInformationYouDiscloseToUs}>
                Personal information you disclose to us
            </h3>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We collect personal information that
                you provide to us.
            </p>
            <p>
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services, or otherwise
                when you contact us.
            </p>
            <p>
                <strong>Personal Information Provided by You.</strong> The
                personal information that we collect depends on the context of
                your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
                <li>names</li>
                <li>email addresses</li>
                <li>mailing addresses</li>
                <li>billing addresses</li>
                <li>phone numbers</li>
            </p>
            <p>
                <strong>Sensitive Information.</strong> We do not process
                sensitive information.
            </p>
            <p>
                <strong>Payment Data.</strong> We may collect data necessary to
                process your payment if you make purchases, such as your payment
                instrument number, and the security code associated with your
                payment instrument. All payment data is stored by Stripe. You
                may find their privacy notice link here:{' '}
                <a href='https://stripe.com/gb/privacy'>
                    https://stripe.com/gb/privacy
                </a>
                .
            </p>
            <p>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
            </p>
            <h3>Information automatically collected</h3>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: Some information — such as your
                Internet Protocol (IP) address and/or browser and device
                characteristics — is collected automatically when you visit our
                Services.
            </p>
            <p>
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes
            </p>
            <p>
                Like many businesses, we also collect information through
                cookies and similar technologies. You can find out more about
                this in our Cookie Notice here:{' '}
                <a href='https://suchamood.com/cookie-policy'>
                    https://suchamood.com/cookie-policy
                </a>
                .
            </p>
            <p>
                The information we collect includes:
                <li>
                    Log and Usage Data. Log and usage data is service-related,
                    diagnostic, usage, and performance information our servers
                    automatically collect when you access or use our Services
                    and which we record in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type, and settings and
                    information about your activity in the Services (such as the
                    date/time stamps associated with your usage, pages and files
                    viewed, searches, and other actions you take such as which
                    features you use), device event information (such as system
                    activity, error reports (sometimes called 'crash dumps'),
                    and hardware settings).
                </li>
                <li>
                    Device Data. We collect device data such as information
                    about your computer, phone, tablet, or other device you use
                    to access the Services. Depending on the device used, this
                    device data may include information such as your IP address
                    (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet
                    service provider and/or mobile carrier, operating system,
                    and system configuration information.
                </li>
                <li>
                    Location Data. We collect location data such as information
                    about your device's location, which can be either precise or
                    imprecise. How much information we collect depends on the
                    type and settings of the device you use to access the
                    Services. For example, we may use GPS and other technologies
                    to collect geolocation data that tells us your current
                    location (based on your IP address). You can opt out of
                    allowing us to collect this information either by refusing
                    access to the information or by disabling your Location
                    setting on your device. However, if you choose to opt out,
                    you may not be able to use certain aspects of the Services.
                </li>
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={twoHowDoWeProcessYourInformation}>
                2. HOW DO WE PROCESS YOUR INFORMATION?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We process your information to
                provide, improve, and administer our Services, communicate with
                you, for security and fraud prevention, and to comply with law.
                We may also process your information for other purposes with
                your consent.
            </p>
            <p>
                <strong>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                </strong>
                <li>
                    <strong>
                        To facilitate account creation and authentication and
                        otherwise manage user accounts.{' '}
                    </strong>
                    We may process your information so you can create and log in
                    to your account, as well as keep your account in working
                    order.
                </li>
                <li>
                    <strong>
                        To save or protect an individual's vital interest.{' '}
                    </strong>
                    We may process your information when necessary to save or
                    protect an individual's vital interest, such as to prevent
                    harm.
                </li>
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={threeWhatLegalBasesDoWeRelyOnToProcessYourInformation}>
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We only process your personal
                information when we believe it is necessary and we have a valid
                legal reason (i.e. legal basis) to do so under applicable law,
                like with your consent, to comply with laws, to provide you with
                services to enter into or fulfil our contractual obligations, to
                protect your rights, or to fulfil our legitimate business
                interests.
            </p>
            <p style={{ fontStyle: 'italic' }}>
                <strong>
                    <u>
                        If you are located in the EU or UK, this section applies
                        to you.
                    </u>
                </strong>
            </p>
            <p>
                The General Data Protection Regulation (GDPR) and UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
            </p>
            <li>
                <strong>Consent. </strong>
                We may process your information if you have given us permission
                (i.e. consent) to use your personal information for a specific
                purpose. You can withdraw your consent at any time. Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => scrollToSection(withdrawYourConsent)}
                >
                    here
                </span>{' '}
                to learn more.
            </li>
            <li>
                <strong>Legal Obligations. </strong>
                We may process your information where we believe it is necessary
                for compliance with our legal obligations, such as to cooperate
                with a law enforcement body or regulatory agency, exercise or
                defend our legal rights, or disclose your information as
                evidence in litigation in which we are involved.
            </li>
            <li>
                <strong>Vital Interests. </strong>
                We may process your information where we believe it is necessary
                to protect your vital interests or the vital interests of a
                third party, such as situations involving potential threats to
                the safety of any person.
            </li>
            <p style={{ fontStyle: 'italic' }}>
                <strong>
                    <u>
                        If you are located in Canada, this section applies to
                        you.
                    </u>
                </strong>
            </p>
            <p>
                We may process your information if you have given us specific
                permission (i.e. express consent) to use your personal
                information for a specific purpose, or in situations where your
                permission can be inferred (i.e. implied consent). You can
                withdraw your consent at any time. Click{' '}
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() => scrollToSection(withdrawYourConsent)}
                >
                    here
                </span>{' '}
                to learn more.
            </p>
            <p>
                In some exceptional cases, we may be legally permitted under
                applicable law to process your information without your consent,
                including, for example:
            </p>
            <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
            </li>
            <li>For investigations and fraud detection and prevention</li>
            <li>
                For business transactions provided certain conditions are met
            </li>
            <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
            </li>
            <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
            </li>
            <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
            </li>
            <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
            </li>
            <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
            </li>
            <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
            </li>
            <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
            </li>
            <li>
                If the information is publicly available and is specified by the
                regulations
            </li>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={fourWhenAndWhomDoWeShareYourPersonalInformation}>
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We may share information in specific
                situations described in this section and/or with the following
                categories of third parties.
            </p>
            <p>
                <strong>
                    Vendors, Consultants, and Other Third-Party Service
                    Providers.{' '}
                </strong>
                We may share your data with third-party vendors, service
                providers, contractors, or agents ('
                <strong>third parties</strong>') who perform services for us or
                on our behalf and require access to such information to do that
                work. The categories of third parties we may share personal
                information with are as follows:
            </p>
            <li>Ad Networks</li>
            <li>Cloud Computing Services</li>
            <li>Data Storage Service Providers</li>
            <li>Payment Processors</li>
            <li>Website Hosting Service Providers</li>
            <li>User Account Registration & Authentication Services</li>
            <li>Order Fulfilment Service Providers</li>
            <li>Performance Monitoring Tools</li>
            <li>Sales & Marketing Tools</li>
            <p>
                We also may need to share your personal information in the
                following situations:
            </p>
            <li>
                <strong>Business Transfers</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
            </li>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={fiveDoWeUseCookiesAndOtherTrackingTechnologies}>
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We may use cookies and other tracking
                technologies to collect and store your information.
            </p>
            <p>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice here:{' '}
                <a href='https://suchamood.com/cookie-policy'>
                    https://suchamood.com/cookie-policy
                </a>
                .
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={sixHowLongDoWeKeepYourInformation}>
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We keep your information for as long
                as necessary to fulfil the purposes outlined in this privacy
                notice unless otherwise required by law.
            </p>
            <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting, or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than the period of time in which users
                have an account with us.
            </p>
            <p>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymise such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={sevenHowDoWeKeepYourInformationSafe}>
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We aim to protect your personal
                information through a system of organisational and technical
                security measures.
            </p>
            <p>
                We have implemented appropriate and reasonable technical and
                organisational security measures designed to protect the
                security of any personal information we process. However,
                despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information
                storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorised third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information. Although we will do our best to protect your
                personal information, transmission of personal information to
                and from our Services is at your own risk. You should only
                access the Services within a secure environment.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={eightDoWeCollectInformationFromMinors}>
                8. DO WE COLLECT INFORMATION FROM MINORS?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: We do not knowingly collect data from
                or market to children under 18 years of age.
            </p>
            <p>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Services, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Services. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at{' '}
                <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={nineWhatAreYourPrivacyRights}>
                9. WHAT ARE YOUR PRIVACY RIGHTS?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: In some regions, such as the European
                Economic Area (EEA), United Kingdom (UK), and Canada, you have
                rights that allow you greater access to and control over your
                personal information. You may review, change, or terminate your
                account at any time.
            </p>
            <p>
                In some regions (like the EEA, UK, and Canada), you have certain
                rights under applicable data protection laws. These may include
                the right (i) to request access and obtain a copy of your
                personal information, (ii) to request rectification or erasure;
                (iii) to restrict the processing of your personal information;
                and (iv) if applicable, to data portability. In certain
                circumstances, you may also have the right to object to the
                processing of your personal information. You can make such a
                request by contacting us by using the contact details provided
                in the section '
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            thirteenHowCanYouContactUsAboutThisNotice
                        )
                    }
                >
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
                ' below.
            </p>
            <p>
                We will consider and act upon any request in accordance with
                applicable data protection laws.
            </p>
            <p>
                If you are located in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your local data protection supervisory
                authority. You can find their contact details here:{' '}
                <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>
                .
            </p>
            <p>
                If you are located in Switzerland, the contact details for the
                data protection authorities are available here:{' '}
                <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                </a>
                .
            </p>
            <p ref={withdrawYourConsent}>
                <strong>
                    <u>Withdrawing your consent:</u>
                </strong>
                If we are relying on your consent to process your personal
                information, which may be express and/or implied consent
                depending on the applicable law, you have the right to withdraw
                your consent at any time. You can withdraw your consent at any
                time by contacting us by using the contact details provided in
                the section '
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            thirteenHowCanYouContactUsAboutThisNotice
                        )
                    }
                >
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
                ' below or by filling out our data subject request form
                available in the account page here:{' '}
                <a href='https://suchamood.com/account'>
                    https://suchamood.com/account
                </a>
                .
            </p>
            <p>
                However, please note that this will not affect the lawfulness of
                the processing before its withdrawal nor, when applicable law
                allows, will it affect the processing of your personal
                information conducted in reliance on lawful processing grounds
                other than consent.
            </p>
            <p>
                <strong>
                    <u>
                        Opting out of marketing and promotional communications
                    </u>
                </strong>
                You can unsubscribe from our marketing and promotional
                communications at any time by clicking on the unsubscribe link
                in the emails that we send, or by contacting us using the
                details provided in the section '
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            thirteenHowCanYouContactUsAboutThisNotice
                        )
                    }
                >
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
                ' below. You will then be removed from the marketing lists.
                However, we may still communicate with you — for example, to
                send you service-related messages that are necessary for the
                administration and use of your account, to respond to service
                requests, or for other non-marketing purposes.
            </p>
            <h3>Account Information</h3>
            <p>
                If you would at any time like to review or change the
                information in your account or terminate your account, you can
                do so in the account page here:{' '}
                <a href='https://suchamood.com/account'>
                    https://suchamood.com/account
                </a>
            </p>
            <p>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
            </p>
            <p>
                <strong>Cookies and similar technologies</strong>
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of our Services. To opt out of interest-based
                advertising by advertisers on our Services visit{' '}
                <a href='http://www.aboutads.info/choices/'>
                    http://www.aboutads.info/choices/
                </a>
                . For further information, please see our Cookie Notice here:{' '}
                <a href='https://suchamood.com/cookie-policy'>
                    https://suchamood.com/cookie-policy
                </a>
                .
                <p>
                    If you have questions or comments about your privacy rights,
                    you may email us at{' '}
                    <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>
                </p>
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={tenControlsForDoNotTrackkFeatures}>
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h2>
            <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ('DNT') feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognising and implementing DNT signals has been finalised. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={elevenDoCaliforniaResidentsHaveSpecificPrivacyRights}>
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: Yes, if you are a resident of
                California, you are granted specific rights regarding access to
                your personal information.
            </p>
            <p>
                California Civil Code Section 1798.83, also known as the 'Shine
                The Light' law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
            </p>
            <p>
                If you are under 18 years of age, reside in California, and have
                a registered account with Services, you have the right to
                request removal of unwanted data that you publicly post on the
                Services. To request removal of such data, please contact us
                using the contact information provided below and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Services, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g. backups, etc.).
            </p>
            <h3>CCPA Privacy Notice</h3>
            <p>The California Code of Regulations defines a 'resident' as:</p>
            <li>
                (1) every individual who is in the State of California for other
                than a temporary or transitory purpose and
            </li>
            <li>
                (2) every individual who is domiciled in the State of California
                who is outside the State of California for a temporary or
                transitory purpose
            </li>
            <p>All other individuals are defined as 'non-residents'.</p>
            <p>
                If this definition of 'resident' applies to you, we must adhere
                to certain rights and obligations regarding your personal
                information.
            </p>
            <h4>What categories of personal information do we collect?</h4>
            <p>
                We have collected the following categories of personal
                information in the past twelve (12) months:
            </p>
            <table>
                <tr>
                    <th>Category</th>
                    <th>Examples</th>
                    <th>Collected</th>
                </tr>
                <tr>
                    <td>A. Identifiers</td>
                    <td>
                        Contact details, such as real name, alias, postal
                        address, telephone or mobile contact number, unique
                        personal identifier, online identifier, Internet
                        Protocol address, email address, and account name
                    </td>
                    <td>YES</td>
                </tr>
                <tr>
                    <td>
                        B. Personal information categories listed in the
                        California Customer Records statute
                    </td>
                    <td>
                        Name, contact information, education, employment,
                        employment history, and financial information
                    </td>
                    <td>YES</td>
                </tr>
                <tr>
                    <td>
                        C. Protected classification characteristics under
                        California or federal law
                    </td>
                    <td>Gender and date of birth</td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>D. Commercial information</td>
                    <td>
                        Transaction information, purchase history, financial
                        details, and payment information
                    </td>
                    <td>YES</td>
                </tr>
                <tr>
                    <td>E. Biometric information</td>
                    <td>Fingerprints and voiceprints</td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>F. Internet or other similar network activity</td>
                    <td>
                        Browsing history, search history, online behaviour,
                        interest data, and interactions with our and other
                        websites, applications, systems, and advertisements
                    </td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>G. Geolocation data</td>
                    <td>Device location</td>
                    <td>YES</td>
                </tr>
                <tr>
                    <td>
                        H. Audio, electronic, visual, thermal, olfactory, or
                        similar information
                    </td>
                    <td>
                        Images and audio, video or call recordings created in
                        connection with our business activities
                    </td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>I. Professional or employment-related information</td>
                    <td>
                        Business contact details in order to provide you our
                        Services at a business level or job title, work history,
                        and professional qualifications if you apply for a job
                        with us
                    </td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>J. Education Information</td>
                    <td>Student records and directory information</td>
                    <td>NO</td>
                </tr>
                <tr>
                    <td>K. Inferences drawn from other personal information</td>
                    <td>
                        Inferences drawn from any of the collected personal
                        information listed above to create a profile or summary
                        about, for example, an individual’s preferences and
                        characteristics
                    </td>
                    <td>NO</td>
                </tr>
            </table>
            <p>
                We may also collect other personal information outside of these
                categories through instances where you interact with us in
                person, online, or by phone or mail in the context of:
            </p>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
            </li>
            <h4>How do we use and share your personal information?</h4>
            <p>
                Suchamood collects and shares your personal information through:
            </p>
            <li>Targeting cookies/Marketing cookies</li>
            <p>
                More information about our data collection and sharing practices
                can be found in this privacy notice and our Cookie Notice here:{' '}
                <a href='https://suchamood.com/cookie-policy'>
                    https://suchamood.com/cookie-policy
                </a>
            </p>
            <p>
                You may contact us by email at{' '}
                <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>
            </p>
            <p>
                If you are using an authorised agent to exercise your right to
                opt out we may deny a request if the authorised agent does not
                submit proof that they have been validly authorised to act on
                your behalf.
            </p>
            <h3>Will your information be shared with anyone else?</h3>
            <p>
                We may disclose your personal information with our service
                providers pursuant to a written contract between us and each
                service provider. Each service provider is a for-profit entity
                that processes the information on our behalf.
            </p>
            <p>
                We may use your personal information for our own business
                purposes, such as for undertaking internal research for
                technological development and demonstration. This is not
                considered to be 'selling' of your personal information.
            </p>
            <p>
                Suchamood has not sold any personal information to third parties
                for a business or commercial purpose in the preceding twelve
                (12) months. Suchamood has disclosed the following categories of
                personal information to third parties for a business or
                commercial purpose in the preceding twelve (12) months:
            </p>
            <li>
                Category A. Identifiers, such as contact details like your real
                name, alias, postal address, telephone or mobile contact number,
                unique personal identifier, online identifier, Internet Protocol
                address, email address, and account name.
            </li>
            <li>
                Category B. Personal Information, as defined in the California
                Customer Records law, such as your name, contact information,
                education, employment, employment history, and financial
                information.
            </li>
            <li>
                Category D. Commercial information, such as transaction
                information, purchase history, financial details, and payment
                information.
            </li>
            <li>Category G. Geolocation data, such as device location.</li>
            <p>
                The categories of third parties to whom we disclosed personal
                information for a business or commercial purpose can be found
                under '
                <span
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onClick={() =>
                        scrollToSection(
                            fourWhenAndWhomDoWeShareYourPersonalInformation
                        )
                    }
                >
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </span>
                '.
            </p>
            <h3>Your rights with respect to your personal data</h3>
            <u>Right to request deletion of the data — Request to delete</u>
            <p>
                You can ask for the deletion of your personal information. If
                you ask us to delete your personal information, we will respect
                your request and delete your personal information, subject to
                certain exceptions provided by law, such as (but not limited to)
                the exercise by another consumer of his or her right to free
                speech, our compliance requirements resulting from a legal
                obligation, or any processing that may be required to protect
                against illegal activities.
            </p>
            <u>Right to be informed — Request to know</u>
            <p>Depending on the circumstances, you have a right to know:</p>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
                the purposes for which the collected personal information is
                used;
            </li>
            <li>whether we sell your personal information to third parties;</li>
            <li>
                the categories of personal information that we sold or disclosed
                for a business purpose;
            </li>
            <li>
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
            </li>
            <li>
                the business or commercial purpose for collecting or selling
                personal information.
            </li>
            <p>
                In accordance with applicable law, we are not obligated to
                provide or delete consumer information that is de-identified in
                response to a consumer request or to re-identify individual data
                to verify a consumer request.
            </p>
            <u>
                Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights
            </u>
            <p>
                We will not discriminate against you if you exercise your
                privacy rights.
            </p>
            <u>Verification process</u>
            <p>
                Upon receiving your request, we will need to verify your
                identity to determine you are the same person about whom we have
                the information in our system. These verification efforts
                require us to ask you to provide information so that we can
                match it with information you have previously provided us. For
                instance, depending on the type of request you submit, we may
                ask you to provide certain information so that we can match the
                information you provide with the information we already have on
                file, or we may contact you through a communication method (e.g.
                phone or email) that you have previously provided to us. We may
                also use other verification methods as the circumstances
                dictate.
            </p>
            <p>
                We will only use personal information provided in your request
                to verify your identity or authority to make the request. To the
                extent possible, we will avoid requesting additional information
                from you for the purposes of verification. However, if we cannot
                verify your identity from the information already maintained by
                us, we may request that you provide additional information for
                the purposes of verifying your identity and for security or
                fraud-prevention purposes. We will delete such additionally
                provided information as soon as we finish verifying you.
            </p>
            <u>Other privacy rights</u>
            <li>
                You may object to the processing of your personal information.
            </li>
            <li>
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
            </li>
            <li>
                You can designate an authorised agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorised agent that does not submit proof that they have been
                validly authorised to act on your behalf in accordance with the
                CCPA.
            </li>
            <li>
                You may request to opt out from future selling of your personal
                information to third parties. Upon receiving an opt-out request,
                we will act upon the request as soon as feasibly possible, but
                no later than fifteen (15) days from the date of the request
                submission.
            </li>
            <p>
                To exercise these rights, you can contact us by email at{' '}
                <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>, or
                by referring to the contact details at the bottom of this
                document. If you have a complaint about how we handle your data,
                we would like to hear from you.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={twelveDoWeMakeUpdatesToThisNotice}>
                12. DO WE MAKE UPDATES TO THIS NOTICE?
            </h2>
            <p style={{ fontStyle: 'italic' }}>
                <strong>In Short</strong>: Yes, we will update this notice as
                necessary to stay compliant with relevant laws.
            </p>
            <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated 'Revised' date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2 ref={thirteenHowCanYouContactUsAboutThisNotice}>
                13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h2>
            <p>
                If you have questions or comments about this notice, you may
                email us at{' '}
                <a href='mailto:hello@suchamood.com'>hello@suchamood.com</a>
            </p>

            {/*/////////////////////////////////////////////////////////////////////*/}

            <h2
                ref={
                    fourteenHowCanYouReviewUpdateOrDeleteTheDataWeCollectFromYou
                }
            >
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
            </h2>
            <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please
                visit the account page:{' '}
                <a href='https://suchamood.com/account'>
                    https://suchamood.com/account
                </a>
                .
            </p>
        </Container>
    );
};
