import React, { createContext, useContext, useReducer } from 'react';

import { findShippingCountry } from '../../components/shippingCountry/findShippingCountry';
import { getUserIpAddress } from '../../http/userIpAddress/getUserIpAddress';
import { Config, defaultConfig } from './Config';

const setConfigData = 'setConfigData';

type Action = { type: typeof setConfigData; payload: Config };

type Dispatch = (action: Action) => void;

interface State {
    configData: Config;
}

const Reducer = (state: State, action: Action) => {
    switch (action.type) {
        case setConfigData: {
            const { payload } = action;
            const newState: State = { ...state, configData: payload };
            return newState;
        }
        default: {
            return { ...state };
        }
    }
};

const ConfigContext = createContext<State | undefined>(undefined);
const ConfigDispatchContext = createContext<Dispatch | undefined>(undefined);

interface ProviderProps {
    children: React.ReactNode;
}

const ConfigProvider = ({ children }: ProviderProps) => {
    const [state, dispatch] = useReducer(Reducer, {
        configData: defaultConfig,
    });

    return (
        <ConfigContext.Provider value={state}>
            <ConfigDispatchContext.Provider value={dispatch}>
                {children}
            </ConfigDispatchContext.Provider>
        </ConfigContext.Provider>
    );
};

const initialiseConfig = async (dispatch: Dispatch) => {
    try {
        await getUserIpAddress().then((res) => {
            const userShippingCountry = findShippingCountry(res.country_code);
            const config: Config = {
                selectedShippingCountry:
                    userShippingCountry.countryCode.toUpperCase(),
                selectedCurrency:
                    userShippingCountry.currencyCode.toLowerCase(),
            };
            dispatch({
                type: setConfigData,
                payload: config,
            });
        });
    } catch (error: any) {
        console.error('Unable to determine user country.');
        dispatch({
            type: setConfigData,
            payload: defaultConfig,
        });
    }
};

const changeConfig = (dispatch: Dispatch, newConfig: Config) => {
    dispatch({
        type: setConfigData,
        payload: newConfig,
    });
};

function useConfigState() {
    const context = useContext(ConfigContext);
    if (context === undefined) {
        throw new Error(
            'useConfigState must be used within a ConfigContextProvider'
        );
    }
    return context;
}

const useConfigDispatch = () => {
    const context = useContext(ConfigDispatchContext);
    if (!context)
        throw new Error('useConfigDispatch must be within a ProductsProvider');

    return context;
};

export {
    ConfigProvider,
    initialiseConfig,
    changeConfig,
    useConfigState,
    useConfigDispatch,
};
