import { Item } from 'react-use-cart';

import { Product } from '../../http/products/Product';

export const findNewPrice = (
    bagItem: Item,
    products: Product[]
): number | undefined => {
    const newPrice = products.find(
        (product) => product.productId === bagItem.id
    )?.price?.unitAmount;

    return newPrice;
};
