import { Button, Container } from 'react-bootstrap';

interface Props {
    error?: Error;
    isPageLoading?: boolean;
    children: JSX.Element;
}

function StateHandler({ error, isPageLoading, children }: Props) {
    if (error) {
        return (
            <div role='alert'>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={() => window.location.reload()}>
                    Try again...
                </Button>
            </div>
        );
    }

    if (isPageLoading) {
        return <Container>Page Loading...</Container>;
    }
    return children;
}

export default StateHandler;
