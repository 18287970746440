export interface ShippingCountry {
    countryName: string;
    countryCode: string;
    currencyName: string;
    currencyCode: string;
    currencySymbol: string;
}

export const defaultShippingCountry: ShippingCountry = {
    countryName: 'United Kingdom',
    countryCode: 'GB',
    currencyName: 'Pound sterling',
    currencyCode: 'gbp',
    currencySymbol: '£',
};

export const validShippingCountries: ShippingCountry[] = [
    defaultShippingCountry,
    {
        countryName: 'Ireland',
        countryCode: 'IE',
        currencyName: 'Euro',
        currencyCode: 'eur',
        currencySymbol: '€',
    },
    {
        countryName: 'France',
        countryCode: 'FR',
        currencyName: 'Euro',
        currencyCode: 'eur',
        currencySymbol: '€',
    },
    {
        countryName: 'Italy',
        countryCode: 'IT',
        currencyName: 'Euro',
        currencyCode: 'eur',
        currencySymbol: '€',
    },
    {
        countryName: 'Germany',
        countryCode: 'DE',
        currencyName: 'Euro',
        currencyCode: 'eur',
        currencySymbol: '€',
    },
    {
        countryName: 'United States',
        countryCode: 'US',
        currencyName: 'United States dollar',
        currencyCode: 'usd',
        currencySymbol: '$',
    },
    {
        countryName: 'Canada',
        countryCode: 'CA',
        currencyName: 'Canadian dollar',
        currencyCode: 'cad',
        currencySymbol: '$',
    },
];
