import { formatPrice } from '../../helpers/FormatPrice';
import { Order } from '../../http/order/Order';

interface Props {
    order: Order;
}

function OrderSummarySingle({ order }: Props) {
    const orderCurrency = order.currency;
    return (
        <>
            Order Status: {order.orderStatus}
            <br />
            Amount SubTotal: {formatPrice(order.amountSubtotal, orderCurrency)}
            <br />
            Amount Total: {formatPrice(order.amountTotal, orderCurrency)}
            <br />
            Currency: {order.currency}
            <br />
            {order.shippingCost ? (
                <>
                    Shipping Cost SubTotal:{' '}
                    {formatPrice(
                        order.shippingCost.amountSubtotal,
                        orderCurrency
                    )}
                    <br />
                    Shipping Cost Total:{' '}
                    {formatPrice(order.shippingCost.amountTotal, orderCurrency)}
                    <br />
                    Shipping Rate ID: {order.shippingCost.shippingRateId}
                    <br />{' '}
                </>
            ) : (
                <>No shipping costs found for this order.</>
            )}
            {order.products.map((product) => (
                <li key={product.productId}>
                    Product Id: {product.productId}
                    <br />
                    Product Active: {product.active ? 'true' : 'false'}
                    <br />
                    Name: {product.name}
                    <br />
                    Image: {product.image}
                    <br />
                    Price Id: {product.price?.priceId}
                    <br />
                    Currency: {product.price?.currency}
                    <br />
                    Unit amount:{' '}
                    {product.price
                        ? formatPrice(product.price.unitAmount, orderCurrency)
                        : NaN}
                    <br />
                    Price Active: {product.price?.active ? 'true' : 'false'}
                    <br />
                    Quantity: {product.quantity}
                </li>
            ))}
            <br />
            Payment Intent Id: {order.paymentIntentId}
            <br />
            Amount Captured: {formatPrice(order.amountCaptured, orderCurrency)}
            <br />
            Amount Refunded: {formatPrice(order.amountRefunded, orderCurrency)}
            <br />
            Billing Name: {order.billingDetails.name}, Billing Phone:{' '}
            {order.billingDetails.phone}, Billing Email:{' '}
            {order.billingDetails.email}, Billing Address:
            {order.billingDetails.address.line1},{' '}
            {order.billingDetails.address.line2},{' '}
            {order.billingDetails.address.city},{' '}
            {order.billingDetails.address.state},{' '}
            {order.billingDetails.address.country},{' '}
            {order.billingDetails.address.postalCode}
            <br />
            Customer Id: {order.customerId}
            <br />
            Card Payment Brand: {order.cardPaymentBrand}
            <br />
            Card Payment Last 4 Digit: {order.cardPaymentLast4Digit}
            <br />
            Receipt Number: {order.receiptNumber}
            <br />
            Receipt Url: {order.receiptUrl}
            <br />
            Refunded: {order.refunded ? 'true' : 'false'}
            <br />
            {order.shipping ? (
                <>
                    Shipping Name: {order.shipping.name}, Shipping Phone:{' '}
                    {order.shipping.phone}, Shipping Address:{' '}
                    {order.shipping.address.line1},{' '}
                    {order.shipping.address.line2},{' '}
                    {order.shipping.address.city},{' '}
                    {order.shipping.address.state},{' '}
                    {order.shipping.address.country},{' '}
                    {order.shipping.address.postalCode}
                </>
            ) : (
                <>No shipping address found for this order.</>
            )}
            <br />
            Shipping Courier: {order.courier}
            <br />
            Tracking Number: {order.trackingNumber}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </>
    );
}

export default OrderSummarySingle;
