import axios, { AxiosRequestConfig } from 'axios';

const isLocal = process.env.NODE_ENV === 'development';

const axiosConfig: AxiosRequestConfig = {
    baseURL: isLocal
        ? 'http://localhost:5000'
        : 'https://backend.suchamood.com/',
};

export const backendClient = axios.create(axiosConfig);
