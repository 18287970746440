import React from 'react';
import { Container } from 'react-bootstrap';

import { CookieConsentBanner } from '../cookieConsentBanner/CookieConsentBanner';

export const Footer = () => {
    return (
        <Container>
            <a href='/privacy-policy'>Privacy Policy</a>
            <a href='/cookie-policy'>Cookie Policy</a>
            <a href='/terms-of-service'>Terms of Service</a>
            <a href='/shipping-and-returns-policy'>
                Shipping and Returns Policy
            </a>
            <CookieConsentBanner />
        </Container>
    );
};
