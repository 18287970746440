import { backendClient } from '../backendClient';
import { Order } from '../order/Order';

export const getOrder = async (orderId: string) =>
    await backendClient
        .get<Order>('/orderByPaymentIntentId', {
            params: { paymentIntentId: orderId },
        })
        .then((response) => {
            return response.data as Order;
        });
