import React from 'react';
import { Container } from 'react-bootstrap';

export const Contact = () => {
    return (
        <Container>
            <h1>Contact page</h1>
            <div>Email: hello@suchamood.com</div>
        </Container>
    );
};
